.switchbar-outer {
    padding: 2px;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-width: 20px;
    border-color: #e3e3e3;
    border-radius: 4px;
    background-color: #e3e3e3;
    height: 32px;
    margin: 8px 0;
}

.switchbar-outer.editing {
    background-color: #1774a7;
    border-color: #1774a7;
}

.switchbar-option {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #717171;
    font-size: 15px;
    font-weight: normal;
    padding: 0 10px;
}

.switchbar-option.selected {
    background-color: #ffffff;
    font-weight: 600;
    cursor: default;
}

.editing .switchbar-option {
    color: #ffffff;
    opacity: 0.5;
}

.editing .switchbar-option.selected {
    color: #1774a7;
    opacity: 1;
}
