.group-edit-outer {
    display: flex;
    flex-direction: row;
}

.group-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.group-edit-image-outer {
    /* flex: 2; */
}

.group-edit-text-outer {
    flex: 1;
}

.group-remove-member-btn {
    border: 0;
    background-color: transparent;
    color: #af365f;
    padding: 0;
    margin: 0;
    line-height: 24px;
    height: 17px;
    font-size: 15px;
    letter-spacing: -0.15px;
    outline: none !important;
    box-shadow: none !important;
}

.group-remove-member-btn:hover {
    color: #af365fd0;
}

.group-add-member-btn {
    color: rgb(0, 122, 55);
}

.group-add-member-btn:hover {
    color: rgb(0, 122, 55, 0.7);
}

.group-toggle-member-btn {
    border: 0;
    background-color: #ececec;
    color: #0f2b41;
    padding: 4px 20px;
    border-radius: 4px;
    margin: 0;
    line-height: 24px;
    font-size: 15px;
    letter-spacing: -0.15px;
    outline: none !important;
    box-shadow: none !important;
    width: 130px;
    text-align: left;
}

.group-toggle-member-btn:hover {
    color: #af365fd0;
}
