.header-section {
    width: 100%;
}

.header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-section {
    margin-left: auto;
}

.progress-section {
    margin-top: 50px;
}

.back-button {
    color: #0274a7;
    background: none;
    border: none;
    display: flex;
    align-items: center;
}

.login-button {
    background-color: #0274a7;
    color: white;
    border-radius: 20px;
    padding: 5px 15px;
    font-weight: bold;
    border: 1px solid #0274a7;
    margin-left: 15px;
}

.login-button:hover {
    background-color: white;
    color: #0274a7;
}
