.group-image {
    width: 50px;
    height: 40px;
    object-fit: cover;
    margin: 10px 0;
}

.group-container {
    padding: 16px;
}
