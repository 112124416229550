.onboarding-task-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.onboarding-task-status {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    width: 100%;
}

.onboarding-member-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.onboarding-remove-member {
    border: 0;
    background-color: transparent;
    color: #af365f;
    padding: 0;
    margin: 0;
    line-height: 24px;
    font-size: 15px;
    letter-spacing: -0.15px;
    outline: none !important;
    box-shadow: none !important;
    padding: 10px 0px;
    font-weight: 500;
}

.onboarding-remove-member:hover {
    color: #af365f;
}

.task-status-container {
    display: flex;
    margin-top: 100px;
}

.task-status-item {
    display: flex;
    justify-content: space-between;
    width: 450px;
}

.task-status-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
