.container {
    background-color: #ffffff;
    padding: 12px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border-radius: 4px;
    color: #505a62;
}

.disabled {
    color: #717171;
    font-weight: 500;
}

/* Calendar */

.calendar {
    display: block;
    position: relative;
    width: 100%;
    background: #ffffff;
    border: 1px solid #c5c9cd;
    margin-top: 10px;
}

.header {
    display: flex;
    align-self: center;
    align-items: center;
}

.arrow {
    cursor: pointer;
}

.header-month {
    color: #0274a7;
    font-size: 18px;
    margin-left: 30px;
    margin-right: 30px;
    font-weight: 600;
}

.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.calendar .body .row {
    border-bottom: 1px solid #c5c9cd;
}

.calendar .body .row:last-child {
    border-bottom: none;
}

.calendar .body .cell:last-child {
    border-right: none;
}

.days {
    font-weight: 600;
    color: #505a62;
    font-size: 13px;
    border-right: 1px solid #c5c9cd;
    padding: 12px 14px;
}

.calendar .days-row {
    border-bottom: 1px solid #c5c9cd;
}

.calendar .body .cell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 140px;
    border-right: 1px solid #c5c9cd;
    overflow: hidden;
    background: #ffffff;
    padding-top: 10px;
    padding: 0;
}

.cell-date {
    padding: 10px 12px;
}

.cell-with-campaign {
    display: 'flex';
    cursor: pointer;
    /* flex-direction: row; */
    width: 100%;
    align-self: flex-end;
    position: relative;
    height: 35px;
    align-items: center;
}

.rectangle-bar-start {
    width: 8px;
    height: 100%;
}

.rectangle-bar-end {
    width: 8px;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
}

.dot {
    position: absolute;
    top: 3px;
    right: 2px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    opacity: 0.8;
}

.campaign-title {
    font-size: 15px;
    color: #505a62;
}

.stripe {
    border: 1px solid #f3f3f3;
    color: rgb(243, 241, 241);
    background: repeating-linear-gradient(
        -55deg,
        rgb(248, 248, 248),
        #f0ebeb 1px,
        rgb(255, 255, 255) 2px,
        rgb(255, 255, 255) 8px
    );
}
