.table-outer {
    padding-top: 10px;
    /* min-height: 400px; */
}

.table-table2 {
    height: 500px;
}

.table__loader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
