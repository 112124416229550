.knowledgebaseItem-edit-outer {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.knowledgebaseItem-edit-inner-left {
    flex: 6;
}

.knowledgebaseItem-edit-inner-right {
    flex: 4;
}

.knowledgebaseItem-edit-inner-divider {
    margin: 10px;
}

.knowledgebaseItem-type-title {
    color: #0f2b41;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.16px;
}

.knowledgebaseItem-type-desc {
    color: #505a62;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.16px;
}

.knowledgebaseItem-double-row {
    display: flex;
    flex-direction: row;
    border: 1px solid #eee;
    padding: 10px 20px;
}

.knowledgebaseItem-add-category-text {
    font-weight: bold;
    color: #0274a7;
    font-size: 15px;
    padding: 0 2px;
    height: 24px;
    padding-top: 5px;
}

.category-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.category-item-title {
    display: flex;
    width: 300px;
}

.knowledgebase-btn {
    height: 33px;
    line-height: 28px;
    padding: 3px 10px;
    font-size: 15px;
}

.category-item-control-edit-button {
    color: #1874a7;
    outline: 0 !important;
    background: none;
    border: none;
    font-weight: 550;
}

.category-line-outer {
    width: 24px;
    max-width: 24px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.category-line-inner1 {
    flex: 1;
    align-self: stretch;
    border-bottom: 1px solid #d3d8dc;
    border-left: 1px solid #d3d8dc;
}

.category-item:first-of-type .category-line-inner1 {
    min-height: 26px;
}

.category-line-inner2 {
    flex: 1;
    align-self: stretch;
    border-left: 1px solid #d3d8dc;
}

.category-item:last-of-type .category-line-inner2 {
    border-left: none;
}

.category-item:first-of-type .tag-item-title {
    padding-top: 14px;
}

.category-item:first-of-type .option-control-buttons {
    padding-top: 10px;
}

.category-item:first-of-type .tag-new-edit {
    padding-top: 10px;
}

.add-category-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.category-item-control-delete-button {
    color: #af365f;
    outline: 0 !important;
    background: none;
    border: none;
    font-weight: 550;
}

.knowledgebaseItem-category-checkbox-button {
    background-color: transparent;
    border-color: transparent;
    color: #000;
    margin: 8px 0px;
    padding: 0;
    font-size: 14px;
    outline: none !important;
    box-shadow: none !important;
    width: 220px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.knowledgebaseItem-category-checkbox {
    font-size: 22px;
    margin-right: 8px;
}

.knowledgebaseItem-edit-top-outer {
    flex-direction: column;
    padding: 10px 0px 10px;
}

.knowledgebaseItem-edit-top-inner {
    display: flex;
    flex-direction: row;
    padding: 10px 0px 10px;
}

.knowledgebaseItem-type-container-outer {
    display: flex;
    width: 33.33%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.knowledgebaseItem-type-container-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.knowledgebaseItem-type-checkbox-button {
    display: flex;
    background-color: transparent;
    border-color: transparent;
    flex-direction: row;
    outline: none !important;
    box-shadow: none !important;
}

.knowledgebaseItem-type-checkbox {
    font-size: 22px;
    margin-right: 3px;
}

.knowledgebaseItem-edit-inner-categories {
    margin: 15px 0px 20px 0px;
}

.knowledgebaseItem-add-image {
    background-color: #1874a7;
    height: 48px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.47px;
    border: 0;
    color: #fff;
    line-height: 100px;
    cursor: pointer;
}
