.editimage-outer {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.editimage-img {
    height: 200px;
    width: 300px;
    object-fit: contain;
    border: 1px solid #ccc;
    background-color: #ececec;
}

.editimage-dropzone {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    border: 1px dashed #ccc;
}
