.login-outer {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

.login-innerleft {
    display: flex;
    padding: 30px;
    flex: 45;
    align-items: flex-start;
    justify-content: center;
    background-color: #f4f4f4;
}

.login-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-right: 50px;
    margin-top: 70px;
}

.login-error {
    font-size: 11px;
    font-weight: 600;
    color: #800;
}

.login-innerright {
    flex: 55;
    /* overflow: hidden; */
    /* display: flex; */
    /* align-items: stretch;
  justify-content: stretch; */
}

.login-imageright {
    width: 100%;
    height: 100%;
    /* height: 100px; */
}

.login-logo {
    width: 220px;
    height: 56px;
}
