body,
html {
    margin: 0;
    height: 100%;
    background: #260a06;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.inner {
    /* max-width: 1280px; */
    margin: auto;
    min-height: 100vh;
    background: #fff;
}

.outer {
    min-width: 1024px;
    background: #260a06;
}

.react-datepicker-popper {
    z-index: 100 !important;
}
