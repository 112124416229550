.searchrow-outer {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
}

.searchrow-input {
    flex: 1;
    display: block;
    width: 100%;
    height: 42px;
    padding: 8px 16px;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
}

.searchrow-btn-outer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.searchrow-btn {
    height: 40px;
}
