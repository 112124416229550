.small-loader {
    border: 4px solid #888; /* Light grey */
    border-top: 4px solid #fff; /* Blue */
    border-radius: 50%;
    width: 28px;
    height: 28px;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
