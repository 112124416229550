.billing-container {
    display: flex;
    flex: 1;
    width: '100%';
    padding: 50px 24px;
    color: #505a62;
    flex-direction: column;
}

.invoice-history,
.upcoming-bill,
.plan-detail-cont,
.payment-details {
    height: 194;
}

.spinner {
    color: #505a62;
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 9999;
}

.card-small {
    width: 25%;
    height: 194;
}

.card-medium {
    width: 50%;
    height: 194;
}

.billing-header {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.45px;
    margin: 0;
}

.next-bill {
    display: flex;
    flex-direction: column;
    padding-right: 100px;
}

.billing-amount {
    color: #0f2b41;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -0.56px;
    padding: 0;
    margin: 0;
}

.loading {
    font-size: 20px;
    color: #0274a7;
    align-self: center;
    margin-top: 100px;
    font-weight: 600;
}

.billing-due-date {
    font-size: 16px;
    letter-spacing: -0.22px;
}

.plan-details {
    margin: 0;
    padding-top: 5px;
    font-size: 15px;
    letter-spacing: -0.16px;
}

.invoice-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 8px;
}

.pdf-link,
.pdf-link:hover {
    color: #0274a7;
    font-family: Poppins;
    font-size: 15px;
    text-decoration: none;
}

.want-more {
    color: #505a62;
    font-size: 15px;
    letter-spacing: -0.15px;
}

.contact,
.contact:hover {
    color: #0274a7;
    font-weight: 600;
    text-decoration: none;
}

.cancel-plan {
    color: rgba(175, 54, 95, 1);
    font-size: 15px;
    letter-spacing: -0.15px;
    cursor: pointer;
    margin-top: 16px;
}

.reactivate-plan {
    color: #0075ac;
}

.payment-details-modal-outer {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-details-modal-inner {
    width: 650px;
    max-width: 100%;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    padding: 26px 32px;
    position: relative;
}

.payment-details-modal-close {
    color: #0274a7;
    position: absolute;
    top: 30px;
    right: 15px;
}

.payment-details-modal-close:hover {
    cursor: pointer;
}
