.menu-outer {
    width: 270px;
    background-color: #0f2b41;
    padding: 49px 0px 20px 40px;
}

.menu-logo {
    width: 200px;
    height: 55px;
}

.menu-navlink {
    width: 240px;
    display: flex;
    flex-direction: column;
    padding: 8px 20px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-top: 3px;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: -0.2px;
    color: #fff;
    text-decoration: none;
}

.menu-subnavlink {
    color: #0f2b41;
    text-decoration: none;
    font-size: 15px;
    font-weight: 300;
    padding: 2px 8px;
    display: none;
}

.menu-subnavlink:first-of-type {
    padding-top: 8px;
}

.menu-navlink.active > .menu-subnavlink {
    display: block;
}

.menu-subnavlink.active {
    font-weight: 500;
}

.menu-subnavlink:hover {
    color: #0f2b41;
    background-color: rgba(157, 151, 180, 0.125);
}

.menu-subnavlink:link,
.menu-subnavlink:visited {
    text-decoration: none;
}

.menu-navlink:link,
.menu-navlink:visited {
    text-decoration: none;
}

.menu-navlink:hover {
    background-color: #ffffff20;
    color: #eee;
}

a.menu-navlink:first-of-type {
    margin-top: 28px;
}

.menu-navlink.active {
    background-color: #f4f4f4;
    color: #0f2b41;
    font-weight: 600;
}

.menu-line {
    background-color: #ffffff80;
    width: 170px;
    margin-left: 20px;
}

.menu-signout {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
}

.menu-reddot {
    width: 12px;
    height: 12px;
    background-color: #cf376b;
    margin-right: 16px;
    border-radius: 6px;
}

.menu-poweredby {
    margin: 38px 12px;
    width: 120px;
    height: 50px;
}

.support-box {
    color: #e66d6d;
    padding-bottom: 20px;
    margin: -25px 0 20px 0;
    border-bottom: 1px white solid;
}

.support-box h4 {
    color: #e66d6d;
}
