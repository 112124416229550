.banner-container {
    display: flex;
    flex-direction: column;
    background-color: #dc143c4d;
    padding: 8px 12px;
    border-radius: 8px;
    margin: 5px 0px 16px 0px;
}

.banner-title {
    color: #242424;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.16px;
}

.banner-desc {
    color: #3a3a3a;
    font-size: 15px;
    letter-spacing: -0.15px;
}
