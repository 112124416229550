.dashboard-name {
    margin: 0;
}

.dashboard-change-layout {
    height: 48px;
}

.dashboard-widget-drag {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 4px;
    justify-content: center;
    align-items: center;
    color: #999;
}

.dashboard-add-widget {
    color: #0274a7;
}

.dashboard-stats-widget,
.dashboard-pulse-widget,
.dashboard-sessions-widget,
.dashboard-top-widget,
.dashboard-uniquedailysessions-widget,
.dashboard-group-widget {
    margin: 90px;
    padding: 0px;
}

.dasboard-threshold,
.dasboard-settings {
    font-size: 15px;
    font-weight: 500;
    color: #017fb0;
    margin-left: 8px;
}

.dashboard-export {
    margin-right: 10px;
}

.dashboard-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
