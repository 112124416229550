.line {
    display: flex;
    flex: 1;
    height: 1px;
    width: 100%;
    border-style: solid;
    border: 1px solid rgba(151, 151, 151, 0.2);
}

.bold {
    font-weight: 600;
}

.weekday-cont {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.weekday {
    font-size: 13px;
    color: #717171;
}

.content-box {
    display: flex;
    flex-direction: column;
    width: 192px;
    min-height: 200px;
    border: 1px solid #505a62;
    padding: 10px;
}

.add-week {
    display: flex;
    flex: 1;
    align-self: center;
    align-items: center;
    cursor: pointer;
}

.regular-text {
    color: #0f2b41;
    font-size: 15px;
    letter-spacing: -0.15px;
    line-height: 24px;
    margin: 0;
    padding: 0;
}

.blue {
    color: #0274a7;
}
