.main-body-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

.left-images {
  flex: 50 1;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  background: linear-gradient(90deg, rgba(104,196,209,1) 0%, rgba(5,116,167,1) 100%, rgba(104,196,209,1) 100%, rgba(0,0,0,1) 100%);
}

.image-text-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0px 35px 0px 50px;
  padding-top: 105px;
}

.image-text {
    font-size: 60px;
    color: white;
    font-weight: 700;
}

.left-text {
  font-size: 20px;
  color: white;
  letter-spacing: -0.32px;
  line-height: 30px;
  margin: 5px 0px 0px 0px;
  font-weight: 500;
}

.left-subTitle {
  color: white;
}

.right-wrapper {
  display: flex;
  padding: 30px;
  flex: 50 1;
  align-items: center;
  flex-direction: column;
  background-color: #f4f4f4;
  position: relative;
}

.carousel-container {
  display: flex;
  flex-direction: row;
  padding-right: 50px;
  padding-top: 10px;
}

.avatar-container {
  width: 99px;
  display: flex;
}

.carousel-text {
  font-size: 16px;
  color: white;
  letter-spacing: -0.26px;
  line-height: 25px;
  text-align: left;
}

.carousel-text-bold {
  font-size: 16px;
  color: white;
  font-weight: 700;;
  letter-spacing: -0.26px;
  line-height: 25px;
  text-align: left;
}

.carousel-avatar {
  vertical-align: middle;
  width: 99px !important;
  height: 99px;
  border-radius: 50%;
  border: 1px solid white !important;
}

.dot {
  position: relative;
}
