.registration-user-entry-wrapper {
    margin-top: 50px;
    width: 60%;
}

.password-check-list {
    list-style: none;
}

.password-check-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.mdc-notched-outline--notched > * {
    border-color: #0274a7 !important;
}
