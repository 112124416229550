.group-edit-outer {
    display: flex;
    flex-direction: row;
}

.group-edit-text-outer {
    flex: 1;
}

.change-dep-member-btn {
    border: 0;
    background-color: transparent;
    color: #1b26af;
    padding: 0;
    margin: 0;
    line-height: 24px;
    height: 17px;
    font-size: 15px;
    letter-spacing: -0.15px;
    outline: none !important;
    box-shadow: none !important;
}

.change-dep-member-btn:hover {
    color: #1b26af;
}

.group-add-member-btn {
    color: rgb(0, 122, 55);
}

.group-add-member-btn:hover {
    color: rgb(0, 122, 55, 0.7);
}

.group-toggle-member-btn {
    border: 0;
    background-color: #ececec;
    color: #0f2b41;
    padding: 4px 20px;
    border-radius: 4px;
    margin: 0;
    line-height: 24px;
    font-size: 15px;
    letter-spacing: -0.15px;
    outline: none !important;
    box-shadow: none !important;
    width: 130px;
    text-align: left;
}

.group-toggle-member-btn:hover {
    color: #af365fd0;
}
