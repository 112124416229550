.maincontainer h4 {
    flex: auto;
}
.tags-board {
    display: flex;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-end;
}

.tag-plate {
    flex: 45%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 5px;
    max-width: 48%;
    background: white;
    margin: 10px;
    min-height: 250px;
}
.tag-plate input {
    border: #717171 2px solid;
    border-radius: 3px;
}

.tag-name {
    flex: 1 0;
    font-size: 18px;
    font-weight: 550;
}
.tag-name-new {
    display: flex;
}

.tag-options {
    flex: 5 0;
}

.tag-create-buttons {
    flex: 1 0;
    bottom: 0;
}

.tag-add-option-button {
    margin: 0 0 10px 10px;
}
.tag-delete-button {
    float: right;
    margin: 0 10px 10px 0;
}

.tag-option {
    /*height: 50px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 10px 0 40px;
}

.tag-option-binary {
    margin: 12px 10px;
}

.tag-option > :first-child {
    flex: auto;
}

.option-control-buttons {
    min-width: 80px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.option-control-buttons * {
    background: none;
    border: none;
    font-weight: 550;
}

.button-edit-option {
    color: #1874a7;
    outline: 0 !important;
}

.button-delete-option {
    color: #af365f;
    outline: 0 !important;
}

.tag-title {
    display: inline-block;
    background: #0f2b41;
    margin: 10px 10px 0;
    padding: 3px 10px;
    border-radius: 3px;
    color: white;
}

.tag-type {
    color: #505a62;
}

.vertical-divider {
    font-weight: normal;
    color: #505a62;
}

.create-tag-add-option {
    background: none;
    border: none;
    color: #1874a7;
    font-weight: 550;
    margin: 10px 0;
}

.tag-name-new {
    margin: 0 10px 0 10px;
}

.tag-item-title {
    padding: 4px;
    flex: 1;
}

.tag-new-edit {
    flex: 1;
    display: flex;
}

.tag-option:first-of-type .tag-item-title {
    padding-top: 14px;
}

.tag-option:first-of-type .option-control-buttons {
    padding-top: 10px;
}

.tag-option:first-of-type .tag-new-edit {
    padding-top: 10px;
}

.tag-line-outer {
    width: 24px;
    max-width: 24px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.tag-line-inner1 {
    flex: 1;
    align-self: stretch;
    border-bottom: 1px solid #d3d8dc;
    border-left: 1px solid #d3d8dc;
}

.tag-option:first-of-type .tag-line-inner1 {
    min-height: 26px;
}

.tag-line-inner2 {
    flex: 1;
    align-self: stretch;
    border-left: 1px solid #d3d8dc;
}

.tag-option:last-of-type .tag-line-inner2 {
    border-left: none;
}

.tag-outer-edit {
    flex: 1;
}
