h1,
h2,
h3,
h4,
h5,
p,
body,
span,
input {
    font-family: Poppins, Arial, Helvetica, sans-serif;
    color: #0f2b41;
}

.poppins {
    font-family: Poppins, Arial, Helvetica, sans-serif !important;
}

h1 {
    font-size: 48px;
    font-weight: 900;
    line-height: 72px;
}

h2 {
    font-size: 38px;
    font-weight: bold;
    letter-spacing: -0.75px;
    line-height: 58px;
}

h3 {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -0.65px;
    line-height: 48px;
}

h4 {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.45px;
    line-height: 44px;
}

h5 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.35px;
    line-height: 38px;
}

.grey {
    color: #505a62;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #999;
    font-family: Poppins, Arial, Helvetica, sans-serif !important;
}

.greyText .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.greyText .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.greyText .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #999 !important;
    font-family: Poppins, Arial, Helvetica, sans-serif !important;
}

.mdc-floating-label,
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    color: #666 !important;
    font-family: Poppins, Arial, Helvetica, sans-serif !important;
}

.gradient {
    background-image: linear-gradient(to right, #9fc5e2, #b3e7e2);
}
