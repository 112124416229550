.backrow-outer {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
}

.backrow-back {
    border: 0;
    background-color: transparent;
    color: #0274a7;
    padding: 0;
    margin: 0;
    margin-right: 3px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.35px;
}

.backrow-btn-outer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.backrow-btn {
    height: 40px;
}
