.update-card-form {
    width: 100%;
}

.payment-entry-form-row {
    margin-bottom: 10px;
}

.stripe-input-offset-wrapper {
    padding-top: 16px;
}

.element-label {
    font-weight: 600;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
}

.card-wrapper-horizontal-row {
    margin-top: 0em;
    display: flex;
}

.card-expiry-element {
    border: solid thin red;
}

.expiry-date-wrapper {
    flex-grow: 1;
}

.cvc-wrapper {
    display: flex;
}

.card-element {
    min-width: 100px;
}

.horizontal-element-label {
    margin-bottom: 0.25em;
}

.horizontal-layout {
    display: flex;
    align-items: center;
}

.element-label {
    padding-right: 10px;
    flex-shrink: 0;
}

.form-control {
    color: #495057;
    border-color: #979b9f;
    background-color: transparent;

    height: 56px;
}

.form-control.--error {
    border-color: crimson;
}

.form-control.--focused {
    border: 2px solid #0274a7;
}

.expiry-cvc-form-errors,
.form__error {
    margin-bottom: 10px;
    color: crimson;
    font-size: 14px;
}

.modal-outer {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-inner {
    width: 375px;
    max-width: 100%;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    padding: 26px 32px;
}

.terms-checkbox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.terms-and-conditions-link {
    color: #212121;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.terms-modal-outer {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(134, 134, 134, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.terms-modal-inner {
    background-color: white;
    margin: 40px;
    border-radius: 8px;
}

.terms-modal-text-container {
    padding: 40px 40px 0 40px;
    max-height: 750px;
    overflow: scroll;
}

.terms-modal-button-container {
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.terms-close-button:hover {
    cursor: pointer;
}
