.welcome-dialog-overlay-outer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    font-size: 2em;
    z-index: 9999999 !important;
}

.welcome-dialog-inner {
    background-color: #fff;
    width: 50%;
    border-radius: 20px;
    min-height: 200px;
    margin: 10vh 0px 30vh 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 10px;
}

.welcome-button-container {
    display: flex;
    justify-content: center;
    width: inherit;
}

.welcome-image {
    width: 100%;
}

.welcome-dialog-link-text {
    color: #505a62;
    font-size: 16px;
    font-weight: normal;
    margin: 0px 80px 20px 80px;
    letter-spacing: -0.15px;
    text-align: center;
}

.welcome-dialog-button {
    width: 70%;
    display: flex;
    margin: 10px 0 20px 0;
    align-items: center;
    justify-content: center;
}
