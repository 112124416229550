.onboarding-create {
    color: #0274a7;
}
.onboard-select {
    font-family: Poppins;
}
.onboard-members {
    font-family: Poppins;
}

.add-task-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.add-task-button {
    font-family: Poppins;
}

.task-item {
    display: flex;
    padding: 15px 10px;
    flex-direction: column;
    background-color: white;
    width: 400px;
    border: '10px solid #DDD';
    margin-bottom: 5px;
    justify-content: center;
}

.task-item-title {
    word-wrap: break-word;
    margin-bottom: 5px;
}

.onboarding-selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
