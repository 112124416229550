.header-title {
    font-size: 20px;
    color: #0f2b41;
    font-weight: 600;
}

.content {
    display: flex;
    background-color: white;
    flex: 1;
    flex-direction: row;
    border-top: 1px solid rgba(151, 151, 151, 0.2);
}

.add-item-container {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.content-left {
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    width: 180px;
    flex: 1;
    min-height: 600px;
}

.content-item-type {
    display: flex;
    background-color: #0274a7;
    color: white;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
}

.content-item-type-selected {
    background-color: #f4f4f4;
    color: #0f2b41;
}

.content-right {
    display: flex;
    flex-direction: column;
    flex: 2;
    overflow-y: auto;
    min-height: 500px;
    color: #0f2b41;
    font-size: 15px;
    margin-bottom: 100px;
}
.search-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.search-input {
    opacity: 0.75;
    color: #505a62;
    font-size: 18px;
    letter-spacing: -0.15px;
    line-height: 16px;
    border: 0px;
    padding: 0px 10px;
}
.search-input:focus {
    border: 0px;
    outline-width: 0px;
}
.content-item {
    display: flex;
    cursor: pointer;
    padding: 10px;
    border-top: 1px solid #505a6250;
    align-items: center;
    padding-right: 28px;
    /* justify-content: space-between; */
}

.content-item:last-child {
    border-bottom: 1px solid #505a6250;
}

.content-item-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 780px;
    margin: 0;
    padding: 0;
    font-size: 15px;
}

.content-item-selected {
    background-color: #e5f1f6;
}

.notice-cont {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.notice-img-cont {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.notice-add-img {
    background-color: #1874a7;
    height: 48px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.47px;
    border-radius: 4px;
    color: #fff;
    line-height: 100px;
    cursor: pointer;
}

.notice-img {
    height: 200px;
    width: 300px;
    object-fit: contain;
    border: 1px solid #ccc;
    background-color: #ececec;
}

.poll-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    margin: 24px 16px;
}

.poll-content {
    display: flex;
    /* flex: 1; */
    flex-direction: column;
    /* max-height: 272px; */
    border-radius: 8px;
    border: 1px solid #505a62;
}

.poll-input {
    opacity: 0.5;
    color: #505a62;
    font-size: 16px;
    letter-spacing: -0.16px;
    line-height: 26px;
    border: 0;
    margin: 5px 8px;
    height: 100px;
}

.poll-input:focus {
    border: 0px;
    outline-width: 0px;
}

.poll-option-cont {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 8px 8px;
    background-color: #f4f4f4;
    padding: 12px 24px;
}

.add-option-cont {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #0274a7;
}

.add-option-text {
    color: #0274a7;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin: 0;
    padding: 0;
}
.poll-duration {
    max-height: 60px;
}
