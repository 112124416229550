.campaings-container {
    display: flex;
    flex: 1;
    width: '100%';
    padding: 50px 24px;
    color: #505a62;
    flex-direction: column;
}

.templates {
    margin-top: 10px;
}
.vertical-align {
    display: flex;
    flex-direction: column;
}

.horizontal-align {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.campign-color {
    width: 32px;
    height: 32px;
    border-radius: 4px;
}

.list-calendar {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.23px;
    line-height: 32px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: auto;
}

.vertical-bar {
    color: #bcc1c6;
    font-weight: 500;
}

.filter-label {
    color: #505a62;
    font-size: 15px;
    letter-spacing: -0.15px;
    line-height: 24px;
    margin: 0;
    padding: 0;
}

.edit-btn {
    outline: 0 !important;
    background: none;
    border: none;
    font-weight: 550;
    color: #0274a7;
    cursor: pointer;
}

.content-title {
    color: #505a62;
    font-size: 15px;
    letter-spacing: -0.15px;
    line-height: 24px;
    margin: 0;
    padding: 0;
}

.row {
    border-top: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.report-item-cont {
    display: flex;
    min-width: 120px;
    height: 44px;
    /* width: 80px; */
    background-color: rgba(151, 151, 151, 0.2);
    color: #000008;
    font-weight: 600;
    border-radius: 3px;
    margin-top: 5px;
    text-align: center;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 18px;
}

.report-item-label {
    color: #505a62;
    font-size: 12px;
    width: 110px;
    text-align: center;
}

.poll-option-cont {
    display: flex;
    width: 550px;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    padding: 0px;
    margin-top: 20px;
}

.poll-option-text {
    height: 30px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    color: #000008;
    left: 0px;
    top: 0px;
    /* width: 400px; */
    position: absolute;
    background: linear-gradient(90deg, #0274a750 0%, #0274a7 150%);
}
