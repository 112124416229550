.btn {
    height: 48px;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.47px;
    outline: none !important;
    box-shadow: none !important;
    border: 0;
}

.btn-primary {
    background-color: #0274a7;
    height: 48px;
}

.btn-primary:disabled {
    background-color: #aaa;
}

.btn-primary:disabled {
    background-color: #aaa;
}

.btn-primary:enabled:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #0274a7d0;
    outline: none !important;
    box-shadow: none !important;
}

.btn-primary:focus,
.btn-primary:active {
    background-color: #1874a7;
    outline: none !important;
    box-shadow: none !important;
}

.btn-remove {
    color: rgb(164, 39, 87);
    padding: 0;
}

.btn-remove:disabled {
    color: #aaa;
}

.btn-remove:enabled:hover,
.btn-remove:focus,
.btn-remove:active {
    color: rgb(164, 39, 87);
    outline: none !important;
    box-shadow: none !important;
}

.btn-outline-primary {
    color: #1874a7;
    height: 48px;
    border: 1px solid #1874a7;
}

.btn-outline-primary:enabled:hover,
.tn-outline-primary:focus,
.tn-outline-primary:active {
    background-color: #0274a7d0;
    outline: none !important;
    box-shadow: none !important;
}

.btn-outline-primary:disabled {
    color: #aaa;
    border-color: #aaa;
}

.btn-danger {
    background-color: #af365e;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
    background-color: #af365ed0;
    outline: none !important;
    box-shadow: none !important;
}

.btn-danger:focus,
.btn-danger:active {
    background-color: #af365e;
    outline: none !important;
    box-shadow: none !important;
}

.text-button {
    border: 0;
    background-color: transparent;
    color: #0274a7;
    padding: 0;
    margin: 0;
    line-height: 32px;
    height: 32px;
    font-size: 17px;
    font-weight: 600;
    outline: none !important;
    box-shadow: none !important;
}

.text-tab {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    line-height: 32px;
    height: 32px;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
    outline: none !important;
    box-shadow: none !important;
}

.text-tab-active {
    border: 0;
    background-color: transparent;
    color: #0274a7;
    padding: 0;
    margin: 0;
    line-height: 32px;
    height: 32px;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
    outline: none !important;
    box-shadow: none !important;
    border-bottom: 5px solid #0274a7;
}

.text-button:hover {
    color: #0274a7d0;
}

.blue-button {
    border: 0;
    background-color: #1274a7;
    color: #fff !important;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    border-radius: 4px;
    height: 32px;
    outline: none !important;
    box-shadow: none !important;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.2px;
    outline: none;
}

.blue-button:hover {
    color: #0274a7d0;
}

.text-button-no-height {
    border: 0;
    background-color: transparent;
    color: #0274a7;
    padding: 0;
    margin: 0;
    font-size: 17px;
    font-weight: 600;
    outline: none !important;
    box-shadow: none !important;
}

.text-button-no-height:hover {
    color: #0274a7d0;
}
