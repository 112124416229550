.challenge-edit-outer {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.challenge-edit-inner-left {
    flex: 6;
}

.challenge-edit-inner-divider {
    border-left: 1px solid #eee;
    margin: 20px 20px;
    align-self: stretch;
}

.challenge-edit-inner-right {
    flex: 4;
}

.challenge-double-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
