.notice-edit-outer {
    display: flex;
    flex-direction: row;
}

.notice-edit-image-outer {
    /* flex: 2; */
}

.notice-edit-text-outer {
    flex: 5;
}

.notice-remove-member-btn {
    border: 0;
    background-color: transparent;
    color: #af365f;
    padding: 0;
    margin: 0;
    line-height: 24px;
    height: 17px;
    font-size: 15px;
    letter-spacing: -0.15px;
    outline: none !important;
    box-shadow: none !important;
}

.notice-remove-member-btn:hover {
    color: #af365fd0;
}

.notice-add-member-btn {
    color: rgb(0, 122, 55);
}

.notice-add-member-btn:hover {
    color: rgb(0, 122, 55, 0.7);
}

.notice-toggle-member-btn {
    border: 0;
    background-color: #ececec;
    color: #0f2b41;
    padding: 4px 20px;
    border-radius: 4px;
    margin: 0;
    line-height: 24px;
    font-size: 15px;
    letter-spacing: -0.15px;
    outline: none !important;
    box-shadow: none !important;
    width: 130px;
    text-align: left;
}

.notice-toggle-member-btn:hover {
    color: #af365fd0;
}

.notice-edit-pinned {
    display: flex;
    align-items: center;
    color: #666666;
    pointer-events: none;
    user-select: none;
}

.notice-edit-pinned--active {
    color: #000000;
    cursor: pointer;
    pointer-events: all;
}

.notice-edit-pinned__checkbox {
    margin-right: 8px;
}
