.learning-question {
    padding: 10px 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 0px 5px #eee;
}

.learning-border {
    width: 80px;
    height: 12px;
    background-color: #68c4d1;
    border-radius: 2px;
    margin-bottom: 20px;
}

.learning-title {
    font-size: 18px;
    font-weight: 600;
    color: #505a62;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.learning-answer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.learning-question-title {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 8px;
}

.learning-add-new-button {
    border: 1px solid #1774a7;
    background-color: #68c4d1;
    color: #000;
    padding: 4px 10px;
    margin: 0;
    line-height: 32px;
    font-size: 16px;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 4px;
}

.learning-add-new-button:hover {
    color: #fff;
}

.learning-answer-checkbox-button {
    border: 0;
    color: #000;
    margin: 0;
    font-size: 14px;
    outline: none !important;
    box-shadow: none !important;
    width: 220px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.learning-answer-checkbox {
    font-size: 22px;
    margin-right: 8px;
}

.learning-edit-global {
    pointer-events: none;
}

.learning-edit-global--active {
    pointer-events: all;
}
