.tabrow-outer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 46px;
}

.tabrow-inner {
    display: flex;
    flex-direction: row;
}

.tab-line {
    color: #000;
    margin: 0;
}

.tabrow-tab {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.23px;
    color: #505a62;
    margin-right: 42px;
    line-height: 30px;

    border: 0;
    background-color: transparent;
    padding: 0;
    outline: none !important;
    box-shadow: none !important;
    border-bottom: 3px solid transparent;
}

.tabrow-tab.active {
    color: #0274a7;
    border-bottom: 3px solid #0274a7;
}
