.edittext-outer {
    padding: 0;
    flex: 1;
    width: 100%;
}

.edittext-help {
    font-size: 12px;
    color: #666;
    margin: 2px 8px;
}

.mdc-text-field {
    will-change: auto !important;
}

.mdc-text-field textarea:disabled,
.mdc-text-field input:disabled,
.mdc-text-field select:disabled {
    color: rgba(0, 0, 0, 0.37) !important;
}

.mdc-text-field select {
  padding-right: 40px;
  background-size: 40px 40px;
  background: #eee url('../../assets/caret_down.png') no-repeat center right 16px;
}

.quill-rich {
    height: 180px;
    width: 100%;
    margin-bottom: 44px;
}

.ql-toolbar.ql-snow {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.ql-container.ql-snow {
    border: none !important;
}
.editBrowse-button {
    background: #666;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.2px;
    cursor: pointer;
    border-radius: 5px;
    border: '10px solid #000000';
    margin-left: 10px;
    padding: 6px 15px 6px 15px;
    margin-top: 8px;
}

.editBrowse-button.editing {
    background: #1774a7;
}

input[type='file'] {
    display: none;
}

.editBrowse-fileName-container {
    margin-left: 5px;
    justify-content: center;
    align-items: center;
}

.editBrowse-fileName-title {
    background-color: transparent;
    border: 0px solid;
    cursor: default;
}

.editBrowse-fileName-title:focus {
    outline: none;
}

.editBrowse-inner {
    display: flex;
    flex-direction: row;
    min-width: 100px;
    justify-content: center;
    align-items: center;
}

.react-datepicker__day--outside-month {
    visibility: hidden;
}


/*Has no value */
.mdc-notched-outline--notched > .mdc-notched-outline__trailing.mdc-notched-outline__trailing--has-no-value {
  border-color: grey !important;
}

.mdc-notched-outline--notched > .mdc-notched-outline__leading.mdc-notched-outline__leading--has-no-value {
  border-color: grey !important;
}

.mdc-notched-outline--notched > .mdc-notched-outline__notch.mdc-notched-outline__notch--has-no-value {
  border-color: grey !important;
}

/*Not focused */
.mdc-notched-outline--notched > .mdc-notched-outline__trailing.mdc-notched-outline__trailing--not-focused {
  border-width: 1px !important;
}

.mdc-notched-outline--notched > .mdc-notched-outline__leading.mdc-notched-outline__leading--not-focused {
  border-width: 1px !important;
}

.mdc-notched-outline--notched > .mdc-notched-outline__notch.mdc-notched-outline__notch--not-focused {
  border-width: 1px !important;
}

/*Focused */
.mdc-notched-outline--notched > .mdc-notched-outline__notch.mdc-notched-outline__notch--focus {
  border-color: #0274a7 !important;
  border-width: 2px !important;
}

.mdc-notched-outline--notched > .mdc-notched-outline__trailing.mdc-notched-outline__trailing--focus {
  border-color: #0274a7 !important;
  border-width: 2px !important;
}

.mdc-notched-outline--notched > .mdc-notched-outline__leading.mdc-notched-outline__leading--focus {
  border-color: #0274a7 !important;
  border-width: 2px !important;
}

/*Error */
.mdc-notched-outline--notched > .mdc-notched-outline__leading.mdc-notched-outline__leading--error {
  border-color: #af365e !important;
}

.mdc-notched-outline--notched > .mdc-notched-outline__notch.mdc-notched-outline__notch--error {
  border-color: #af365e !important;
}

.mdc-notched-outline--notched > .mdc-notched-outline__trailing.mdc-notched-outline__trailing--error {
  border-color: #af365e !important;
}
