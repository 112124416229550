.settings-row {
    display: flex;
    flex-direction: row;
}

.settings-row__text-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.settings-row__title {
    font-size: large;
    font-weight: 600;
}

.settings-row__switch-container {
    width: 250px;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
