.content-edit-outer {
    display: flex;
    flex-direction: row;
}

.content-edit-image-outer {
    width: 330px;
}

.content-edit-text-outer {
    flex: 5;
}
