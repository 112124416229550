.dialog-overlay-outer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    font-size: 2em;
    z-index: 99999 !important;
}

.dialog-inner {
    background-color: #fff;
    width: 600px;
    min-height: 200px;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 24px;
    position: relative;
    border-radius: 4px;
    /* overflow-y: visible; */
}

.dialog-title {
    color: #0f2b41;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.25px;
}

.dialog-text {
    color: #505a62;
    font-size: 16px;
    font-weight: normal;
    margin: 6px 6px 20px;
    letter-spacing: -0.15px;
    text-align: center;
}

.dialog-text-csverror {
    text-align: left;
    font-size: 14px;
    margin: 0px 0px 2px 0px;
}

.dialog-inner-csv {
    width: 550px;
    align-items: flex-start;
}

.dialog-text-error {
    color: #800;
}

.dialog-link-text {
    color: #505a62;
    font-size: 16px;
    font-weight: normal;
    margin: 2px 20px;
    letter-spacing: -0.15px;
    text-align: center;
}

.dialog-button {
    width: 100%;
    margin: 10px 0;
}

.dialog-close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.dialog-inner .mdc-text-field--outlined .mdc-floating-label {
    top: 10px !important;
}

.editcsv-outer {
    width: 100%;
}

.editcsv-dropzone {
    height: 122px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    font-size: 12px;
    text-align: center;
    border: 1px dashed #ccc;
}

.editcsv-text {
    color: #0274a7;
}

.editcsv-paperclip {
    width: 42px;
    height: 42px;
    border-radius: 21px;
    font-size: 20px;
    background-color: #0274a7;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editdate-dateofbirth {
    font-size: 18px;
    margin-top: 8px;
    max-width: 20px;
}
