.card-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 6px;
}

.settings-text-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 100%;
    padding-right: 10%;
}

.onboarding-card__title {
    padding: 0 0 5px 0;
    font-size: large;
    font-weight: 600;
}

.reset-button {
    min-width: 110px;
}
