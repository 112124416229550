.plan-cancellation-cont {
    background-color: #fff;
    width: 700px;
    min-height: 200px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    padding: 14px 24px;
    position: relative;
    border-radius: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
}

.plan-cancellation-title {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.45px;
    align-self: flex-start;
    margin: 0;
}

.plan-cancellation-desc {
    color: #505a62;
    font-size: 15px;
    letter-spacing: -0.15px;
    margin-top: 5px;
}
