.card {
    background-color: #ffffff;
    min-height: 50px;
    padding: 0;
    margin-bottom: 12px;
    margin-top: 14px;
    border-radius: 6px;
}

.card-title-outer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px 10px;
}

.card-title-outer.editing {
    background-color: #0274a7;
    color: #ffffff;
}

.card-title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.25px;
    color: #0f2b41;
    line-height: 28px;
    margin: 0;
}

.card-title-outer.editing .card-title {
    color: #ffffff;
}

.card-edit {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.2px;
    color: #0274a7;
    padding: 2px 0;
    outline: none;
}

.card-edit:focus {
    outline: none;
}

.card-title-outer.editing .card-edit {
    background-color: #ffffff;
    padding: 2px 8px;
    border-radius: 4px;
}

.card-text {
    color: #666;
}

.card-divider {
    margin: 0;
}

.card-body-outer {
    padding: 10px 15px;
}

.editing .mdc-notched-outline__leading,
.editing .mdc-notched-outline__notch,
.editing .mdc-notched-outline__trailing {
    border-color: #0274a7 !important;
    border-width: 2px !important;
}

.notediting .mdc-notched-outline__leading,
.notediting .mdc-notched-outline__notch,
.notediting .mdc-notched-outline__trailing {
    border-color: #666 !important;
    border-width: 1px !important;
}
